import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppRoutes from "./Routes";
import ErrorBoundary from "./common/ErrorBoundary";
import { AppContext } from "./libs/contextLib";
// import AlertMessage from "./common/AlertMessage";

function App() {
  let navigate = useNavigate();
  return (
    <>
      <Navbar className="border-bottom" sticky="top" bg="white" expand="lg" variant="light">
        <Navbar.Brand>
          <Link to="/" className="navbar-brand ps-3">
            <FontAwesomeIcon icon={['far', 'compass']} />
            <span className="ps-2">Lost in the Wild</span>
          </Link>
        </Navbar.Brand>
      </Navbar>
      <ErrorBoundary>
        <AppContext.Provider value={{ navigate }}>
          <div className="container-fluid bg-light main">
            {/* <div className="row justify-content-center">
              <AlertMessage />
            </div> */}
            <div className="row justify-content-center main">
              <AppRoutes />
            </div>
            <div className="row footer">
              <div className="col-md-12 mt-2 mb-4">
                <ul className="nav justify-content-center">
                  <li className="nav-item"><button className="btn btn-link text-muted" onClick={() => navigate("/privacy-policy")}>Privacy</button></li>
                  <li className="nav-item"><button className="btn btn-link text-muted" onClick={() => navigate("/terms-of-service")}>Terms</button></li>
                  <li className="nav-item"><button className="btn btn-link text-muted" onClick={() => navigate("/support")}>Support</button></li>
                </ul>
              </div>
            </div>
          </div>
        </AppContext.Provider>
      </ErrorBoundary>
    </>
  );
}

export default App;
