import { library } from '@fortawesome/fontawesome-svg-core';
import { faCompass, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane, faUserFriends, faMobile, faBirthdayCake, faPen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(
	faCompass,
	faPaperPlane,
	faUserFriends,
	faEnvelope,
	faMobile,
	faBirthdayCake,
	faPen,
	faPlus,
	faTimes
);